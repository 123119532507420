import EntityList from '../listPage/EntityList';

export default new EntityList(
  'redemptionsVenues',
  'offers/redemptions',
  null,
  undefined,
  undefined,
  'Redemptions Venues',
);
