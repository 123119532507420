// numbers are IDs of appropriate user permission types stored on the backend
export const FANS = 9;
export const FINANCES = 5;
export const SALES = 8;
export const ACCOUNTS = 10;
export const THIRD_PARTY = 11;
export const ADMIN = 6;
export const DEV = 7;
export const PROFILE_BUILDER = 18;
export const UNRESTRICTED_ACCESS = 'UNRESTRICTED_ACCESS';

const canUserAccessPage = (userPermissions, allowAccessArray) => {
  const userPermIds = userPermissions && userPermissions.map(perm => perm.id);
  return Boolean(
    userPermIds &&
      (userPermIds.includes(ADMIN) ||
        userPermIds.includes(DEV) || // admin/dev only
        (allowAccessArray &&
          allowAccessArray.length &&
          (allowAccessArray.includes(UNRESTRICTED_ACCESS) || // anyone can view
            allowAccessArray.some(permissionId => userPermIds.includes(permissionId))))), // user allowed
  );
};

export default canUserAccessPage;
