/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|     |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 17/04/2018
 */
/**
 * Describe the tabulation for each feature.
 * Format :  {{feature: {default: {name: string, location: string}, others: *[]}, ...}}
 */
import { games, quizzes } from '../template/games';

const tabs = {
  adverts: {
    default: {
      name: 'List',
      location: '/adverts',
    },
    others: [
      {
        name: 'New',
        location: '/adverts/create',
      },
    ],
  },
  offer: {
    default: {
      name: 'List',
      location: '/offers',
    },
    others: [
      {
        name: 'New',
        location: '/offers/create',
      },
    ],
  },
  offerRedemptionCodes: {
    default: {
      name: 'List',
      location: '/offers/redemption-code',
    },
    others: [
      {
        name: 'New',
        location: '/offers/redemption-code/create',
      },
      {
        name: 'Generate',
        location: '/offers/redemption-code/bulk/create',
      },
    ],
  },
  promocode: {
    default: {
      name: 'List',
      location: '/venues/pub-portal-code',
    },
    others: [
      {
        name: 'New',
        location: '/venues/pub-portal-code/create',
      },
    ],
  },
  campaign: {
    default: {
      name: 'List',
      location: '/campaigns',
    },
    others: [
      {
        name: 'New',
        location: '/campaigns/create',
      },
    ],
  },
  venueGroups: {
    default: {
      name: 'List',
      location: '/venues/venue-group',
    },
    others: [
      {
        name: 'New',
        location: '/venues/venue-group/create',
      },
    ],
  },
  venueUsers: {
    default: {
      name: 'List',
      location: '/venues/venue-users',
    },
    others: [
      {
        name: 'New',
        location: '/venues/venue-users/create',
      },
    ],
  },
  game: {
    default: {
      name: 'List',
      location: '/games',
    },
    others: [
      {
        name: 'New',
        location: '/games/create',
      },
    ],
  },
  news: {
    default: {
      name: 'List',
      location: '/meta/news',
    },
    others: [
      {
        name: 'New',
        location: '/meta/news/create',
      },
    ],
  },
  venueFinderDataScopes: {
    default: {
      name: 'List',
      location: '/venues/data-scope',
    },
    others: [
      {
        name: 'New',
        location: '/venues/data-scope/create',
      },
    ],
  },
  permissions: {
    default: {
      name: 'List',
      location: '/meta/pub-portal/permissions',
    },
    others: [
      {
        name: 'New',
        location: '/meta/pub-portal/permissions/create',
      },
    ],
  },
  tiers: {
    default: {
      name: 'List',
      location: '/meta/pub-portal/tiers',
    },
    others: [
      {
        name: 'New',
        location: '/meta/pub-portal/tiers/create',
      },
    ],
  },
  events: {
    default: {
      name: 'List',
      location: '/sports/fixtures/',
    },
  },
  consumerEvents: {
    default: {
      name: 'List',
      location: '/sports/service-events/mismatch/',
    },
  },
  member: {
    default: {
      name: 'List',
      location: '/members',
    },
    others: [
      {
        name: 'New',
        location: '/members/create',
      },
    ],
  },
  groupCode: {
    default: {
      name: 'List',
      location: '/venues/group-code',
    },
    others: [
      {
        name: 'New',
        location: '/venues/group-code/create',
      },
    ],
  },
  thirdPartyHouseCodes: {
    default: {
      name: 'List',
      location: '/venues/house-code-owners',
    },
    others: [
      {
        name: 'New',
        location: '/venues/house-code-owners/create',
      },
    ],
  },
  thirdPartyHouseCodeLink: {
    default: {
      name: 'List',
      location: '/venues/house-codes',
    },
    others: [
      {
        name: 'New',
        location: '/venues/house-codes/create',
      },
    ],
  },
  quizTheme: {
    default: {
      name: 'List',
      location: '/games/quizzes/rounds/themes',
    },
    others: [
      {
        name: 'New',
        location: '/games/quizzes/rounds/themes/create',
      },
    ],
  },
  redemptionsVenues: {
    default: {
      name: 'List',
      location: '/offers/redemptions',
    },
    others: [],
  },
  trivia: {
    default: {
      name: 'List',
      location: '/contents/trivias',
    },
    others: [
      {
        name: 'New',
        location: '/contents/trivias/create',
      },
    ],
  },
  socialMediaCompetitionCountries: {
    default: {
      name: 'List',
      location: '/contents/social-media-themes/competition-countries',
    },
    others: [
      {
        name: 'New',
        location: '/contents/social-media-themes/competition-countries/create',
      },
    ],
  },
  channelGroups: {
    default: {
      name: 'List',
      location: '/meta/country-channels',
    },
    others: [
      {
        name: 'New',
        location: '/meta/country-channels/create',
      },
    ],
  },
  assetGroup: {
    default: {
      name: 'List',
      location: '/meta/asset-group',
    },
    others: [
      {
        name: 'New',
        location: '/meta/asset-group/create',
      },
    ],
  },
  channels: {
    default: {
      name: 'List',
      location: '/meta/channels',
    },
  },
  pills: {
    default: {
      name: 'List',
      location: '/meta/pills',
    },
    others: [
      {
        name: 'New',
        location: '/meta/pills/create',
      },
    ],
  },
  importStatus: {
    default: {
      name: 'List',
      location: '/meta/import-status',
    },
  },
  venueCompleter: {
    default: {
      name: 'List',
      location: '/venues/venue-completer',
    },
    others: [
      {
        name: 'New',
        location: '/venues/venue-completer/create',
      },
    ],
  },
  calendarContent: {
    default: {
      name: 'List',
      location: '/meta/calendar-content',
    },
    others: [
      {
        name: 'New',
        location: '/meta/calendar-content/create',
      },
    ],
  },
};

const routeMap = (id, extension, entity) => ({
  default: {
    name: 'List',
    location: `/${entity}/${id}/${extension}`,
  },
  others: [
    {
      name: 'New',
      location: `/${entity}/${id}/${extension}/create`,
    },
  ],
});

const mapGameStuff = (gameString, g, urlExtension) => {
  tabs[`${g.entityName}${gameString}`] = routeMap(g.game, urlExtension, 'games');
};

games.forEach(g => {
  mapGameStuff('Prizes', g, 'prizes');
  mapGameStuff('MonthPrize', g, `${g.leagueType}-league-prize`);
  mapGameStuff('Leagues', g, 'leagues');
  mapGameStuff('PrizesMembers', g, 'prizes-members');
  mapGameStuff('share-copy', g, 'share-copy');
});

const mapQuizTabs = (gameString, q, urlExtension) => {
  tabs[`${q.entityName}${gameString}`] = routeMap(q.id, urlExtension, 'games/quizzes');
};

quizzes.forEach(q => {
  mapQuizTabs('Answers', q, 'answers');
  mapQuizTabs('Questions', q, 'questions');
  mapQuizTabs('Rounds', q, 'rounds');
});

export default tabs;
