import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'material-ui';
import { format } from 'date-fns';
import LoadingWheel from '../../../components/LoadingWheel';
import request from '../../../utils/apiRequest/requests';
import MpLink from '../../../components/MpLink/MpLink';
import { getURLQuery } from '../../../utils/helpers';

const limit = 20;

const Reviews = ({ editedValue }) => {
  const [reviews, setReviews] = useState(null);
  const [offset, setOffset] = useState(0);
  const [hasMorePages, setHasMorePages] = useState(false);

  useEffect(() => {
    request(`ratings/${getURLQuery({ venueId: editedValue, offset, limit })}`).then(r => {
      const data = r.getResult();
      setHasMorePages(data.length === limit);
      if (offset === 0) {
        setReviews(data);
      } else {
        setReviews(oldReviews => [...oldReviews, ...data]);
      }
    });
  }, [editedValue, offset]);

  if (reviews === null) {
    return <LoadingWheel />;
  }

  return reviews?.length === 0 ? (
    <p>No reviews yet</p>
  ) : (
    <>
      <div className="flex flex-wrap gap-4">
        {reviews.map(r => (
          <Review key={r.id} review={r} />
        ))}
      </div>
      {hasMorePages && (
        <button
          className="mt-4 rounded-lg bg-lightGrey px-8 py-1 font-semibold"
          type="button"
          onClick={() => setOffset(limit + offset)}
        >
          Load more
        </button>
      )}
    </>
  );
};

const Review = ({
  review: {
    userReference,
    rating,
    review,
    status,
    ratingAtmosphere,
    ratingService,
    ratingViewingExperience,
    id,
    createdAt,
  },
}) => {
  const [hidden, setHidden] = useState(status === 'hidden');
  return (
    <div className="rounded-lg border border-lightGrey p-4">
      <p className="pb-1">🗣️ {id}</p>
      <MpLink to={`/members/edit?id=${userReference}`} className="font-semibold">
        👤 {userReference}
      </MpLink>
      <p>{format(new Date(createdAt), 'dd/MM/yyyy HH:mm')}</p>
      <p className="pt-1">Overall ★{rating}</p>
      <p>Atmos ★{ratingAtmosphere}</p>
      <p>Service ★{ratingService}</p>
      <p>ViewingExp ★{ratingViewingExperience}</p>
      <p className="max-w-[250px]  py-2 opacity-60">{review}</p>
      <Checkbox
        label="hidden"
        checked={hidden}
        onClick={() => {
          const startValue = hidden;
          setHidden(!startValue);
          request(`/ratings/${id}/${startValue ? 'show' : 'hide'}`, { method: 'POST' })
            .then(r => {
              if (r.status === 202) {
                throw r;
              }
            })
            .catch(() => setHidden(startValue));
        }}
      />
    </div>
  );
};

Reviews.propTypes = {
  editedValue: PropTypes.number.isRequired,
};

Review.propTypes = {
  review: PropTypes.shape().isRequired,
};

export default Reviews;
