/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \          /
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 30/10/2018
 */
import { combineReducers } from 'redux';

/**
 * This is where all forms are imported in the project.
 * By adding a new form the form list, it will added both to React-router (using its "endpoint"
 * value) and registered in the Redux store under the "form" path.
 */

// Form pages.
import member from './member';
import league from './league';
import game from './game';
import fixture from './gameFixture';
import competitionRound from './competitionRound';
import gamePrize from './gamePrize';
import competition from './competition';
import gamePrizeMember from './gamePrizeMember';
import VenuesDataScope from './VenuesDataScope';
import teams from './teams';
import venueGroups from './venueGroups';
import news from './news';
import parameters from './parameters';
import permissions from './permissions';
import tiers from './tiers';
import gameMonthPrize from './gameMonthPrize';
import campaigns from './campaign';
import promocode from './promocode';
import GameBatchPush from './BatchPush/GameBatchPush';
import CSVBatchPush from './BatchPush/CSVBatchPush';
import events from './events';
import offers from './offers';
import GroupCode from './groupCode';
import quiz from './quiz';
import quizMembers from './quizMembers';
import quizRounds from './quizRounds';
import quizThemes from './quizThemes';
import quizQuestions from './quizQuestions';
import quizAnswers from './quizAnswers';
import sports from './sports';
import trivia from './trivia';
import homepageSection from './homepageSection';
import socialMediaCompetitionCountries from './socialMediaCompetitionCountries';
import channelGroups from './channelGroups';
import channels from './channels';
import mandates from './mandates';
import pills from './pills';
import venue from './venue';
import venueUsers from './venueUsers';
import offerCode from './OfferRedemptionCode';
import offerCodeGenerate from './OfferRedemptionCode/generate';
import importStatus from './importStatus';
import calendarContent from './calendarContent';
import adverts from './Adverts';
import venueCompleter from './venueCompleter';
import gameShareCopy from './GameShareCopy';
import assetGroup from './AssetGroup';
import bustCache from './BustCache/BustCache';
import consumerEvents from './consumerEvents';
import thirdPartyHouseCodes from './thirdPartyHouseCodes';
import thirdPartyHouseCodeLink from './thirdPartyHousecodeLink';
import redemptionsVenues from './redemptionsVenues';

const formList = [
  redemptionsVenues,
  thirdPartyHouseCodes,
  thirdPartyHouseCodeLink,
  member,
  ...league,
  venueGroups,
  game,
  fixture,
  competitionRound,
  competition,
  ...gamePrize,
  ...gamePrizeMember,
  ...gameMonthPrize,
  ...gameShareCopy,
  teams,
  news,
  parameters,
  permissions,
  tiers,
  campaigns,
  promocode,
  GameBatchPush,
  events,
  consumerEvents,
  GroupCode,
  quiz,
  ...quizMembers,
  ...quizRounds,
  quizThemes,
  ...quizQuestions,
  ...quizAnswers,
  sports,
  trivia,
  homepageSection,
  socialMediaCompetitionCountries,
  channelGroups,
  channels,
  mandates,
  pills,
  CSVBatchPush,
  offers,
  offerCode,
  offerCodeGenerate,
  venue,
  venueUsers,
  importStatus,
  calendarContent,
  adverts,
  venueCompleter,
  VenuesDataScope,
  assetGroup,
  bustCache,
];

const reducers = {};
let routeList = [];

formList.forEach(form => {
  reducers[form.entityName] = form.reducer;
  routeList = [...routeList, ...form.routes];
});

/**
 * @description Form reducer that will be used in the Redux store.
 *  All form reducers are located under the "form" path in the Redux store.
 * @type {Reducer<any>}
 */

export const reducer = combineReducers({
  ...reducers,
});

/**
 * @description List of forms routes to add to React router.
 * @type {Array<{
 *   path: string,
 *   component: node,
 * }>}
 */
export const routes = routeList;
