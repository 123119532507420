/**
 *    oOOOOOo
 *   ,|    oO
 *  //|     |
 *  \\|     |
 *   `|     |
 *    `-----`
 * MatchPint Ltd
 * @author Pierre Segonne
 * Date: 17/04/2018
 */
import apiRequest, {
  defaultOptions,
  handleRequest,
  handleFormRequest,
} from '../../utils/apiRequest/requests';
import { setShowToast } from '../toast/actions';
import { displayGlobalError } from '../globalError/thunks/globalError';
import { TYPE_UNAUTHORIZED, TYPE_SERVER_ERROR } from '../globalError/constants';

/**
 * @description Manages error behaviour in the redux store.
 * @param {function} dispatch
 * @return {function(Error|ServerError): Promise<never>}
 */
function handleError(dispatch) {
  return function _handleError(error) {
    // If the user is asking an other route that /auth/signin and receives a 401/403 response.
    if (error.isServerError && error.code === 401) {
      dispatch(displayGlobalError(TYPE_UNAUTHORIZED));
    }
    if (error.isServerError && error.code === 500) {
      dispatch(displayGlobalError(TYPE_SERVER_ERROR));
    }

    return Promise.reject(error);
  };
}

const conditionallyIssueToast = (response, dispatch, options) => {
  // if an update request was successfull - display <SuccessToast />
  if (
    response &&
    response.status === 200 &&
    options.method.toUpperCase() === 'PUT' &&
    // check for body to make sure it's not a 'fetch form Description'
    options.body
  ) {
    dispatch(setShowToast(true));
  }
  return response;
};

export function request(path, options = defaultOptions) {
  return function _request(dispatch) {
    return apiRequest(path, options)
      .then(response => conditionallyIssueToast(response, dispatch, options))
      .then(handleRequest)
      .catch(handleError(dispatch));
  };
}

export function formRequest(path, options = defaultOptions) {
  return function _formRequest(dispatch) {
    return apiRequest(path, options)
      .then(response => conditionallyIssueToast(response, dispatch, options))
      .then(handleFormRequest)
      .catch(handleError(dispatch));
  };
}
