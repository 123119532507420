export const swapVariableForNumber = (endpoint, url) => {
  if (endpoint.indexOf(':') > -1) {
    const indexOfVariable = endpoint.indexOf(':');
    const subString = endpoint.substring(indexOfVariable);
    const variableName = subString.substring(0, subString.indexOf('/'));
    const afterVariable = url.substring(indexOfVariable);
    const variableValue = afterVariable.substring(0, afterVariable.indexOf('/'));
    const replacedEndpoint = endpoint.replace(variableName, variableValue);

    return replacedEndpoint;
  }

  return endpoint;
};

/*
 * The idea of this method is to allow listing and form updates to resources that belong
 * to resources for example game/2/leagues/1/players, the endpoint for the list page would
 * be /game/:gameId/leagues/:leagueId/players which we will then need to update the variables
 * for the current route. the first section of the endpoint must not be a variable
 */
export const getEndpointFromDynamic = (endpoint, currentURL) => {
  if (endpoint.indexOf(':') > -1) {
    const match = currentURL.indexOf(endpoint.substring(0, endpoint.indexOf('/')));
    if (match > 0) {
      const shortURL = currentURL.substring(match);
      let newEnd = swapVariableForNumber(endpoint, shortURL);
      while (newEnd.indexOf(':') > -1) {
        newEnd = swapVariableForNumber(newEnd, shortURL);
      }
      return newEnd;
    }
  }

  return endpoint;
};

export const getBase64FromFile = file => {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = readerEvent => resolve(readerEvent.target.result);
    reader.onerror = () => reject(new Error('SOMETHING WENT WRONG'));
    reader.readAsDataURL(file);
  });
};

export const getURLQuery = (parameters = {}, stripNullParams = false) => {
  const BOOLEAN = 'boolean';
  const NUMBER = 'number';
  const OBJECT = 'object';
  const STRING = 'string';

  const params = [];
  const keys = Object.keys(parameters);
  keys.forEach(_key => {
    const key = [encodeURIComponent(_key)];
    if ((parameters[key] === null || parameters[key] === '') && !stripNullParams) {
      params.push(key);
    } else {
      switch (typeof parameters[key]) {
        case BOOLEAN:
          params.push(`${key}=${parameters[key] ? '1' : '0'}`);
          break;
        case NUMBER:
        case STRING:
          params.push(`${key}=${encodeURIComponent(parameters[key])}`);
          break;
        case OBJECT: {
          if (Array.isArray(parameters[key])) {
            params.push(parameters[key].map(param => `${key}[]=${param}`).join('&'));
          }
          break;
        }
        default:
          break;
      }
    }
  });

  return params.length === 0 ? '' : `?${params.join('&')}`;
};
