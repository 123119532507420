import redemptionsVenues from './entityList';

import createListPage from '../listPage';
import tabs from '../../../routes/tabs';

const redemptionsVenueList = createListPage(
  redemptionsVenues,
  {
    id: {
      label: 'id',
    },
    name: {
      label: 'name',
    },
  },
  {
    navTabs: tabs.redemptionsVenues,
  },
);

export default redemptionsVenueList;
