import EntityList from '../listPage/EntityList';

const groupCodeList = new EntityList(
  'venueHouseCodes',
  'venues/house-codes',
  {},
  undefined,
  undefined,
  'Third Party Housecode Link',
);

export default groupCodeList;
