import EntityList from '../listPage/EntityList';

const groupCodeList = new EntityList(
  'houseCodeOwners',
  'venues/house-code-owners',
  {},
  undefined,
  undefined,
  'Third Party Housecodes',
);

export default groupCodeList;
