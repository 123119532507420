import React from 'react';
import AutoInAndOutSelector from '../../../components/AutoInAndOutSelector';
import createFormPage from '../formPage';

const params = {
  endpoint: 'offers/redemptions',
  sections: {
    General: [
      'id',
      'name',
      'offerTitle',
      'claimableLength',
      'countdown',
      'localeId',
      'validFrom',
      'validTo',
      'redeemerAge',
      'themeId',
      'voucherEmailSubject',
      'voucherEmailText',
      'fromEmail',
      'fromEmailName',
    ],
    Venues: [
      {
        fieldPath: 'id',
        // eslint-disable-next-line react/prop-types
        Component: ({ editedValue }) => (
          <AutoInAndOutSelector
            enableCSVUpload
            activeItemsString="Venues Running the offer"
            availableItemsString="Available Venues"
            formatName={data =>
              data.map(item => ({
                id: item.id,
                name: item.name,
              }))
            }
            endpoints={{
              fetchActive: `venues/running-redemption-offer/${editedValue}?`,
              fetchAvailable: `venues/not-running-redemption-offer/${editedValue}?`,
              add: `offers/redemptions/${editedValue}/venues/add`,
              remove: `offers/redemptions/${editedValue}/venues/remove`,
            }}
          />
        ),
      },
    ],
  },
};

export default createFormPage('redemptionOffer', params);
