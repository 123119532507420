import EntityList from '../listPage/EntityList';

export default new EntityList(
  'venueFinderDataScopes',
  'venues/data-scope/',
  undefined,
  undefined,
  undefined,
  'Venue Finder Data Scopes',
);
