import createFormPage from '../formPage';

const params = {
  endpoint: 'venues/house-code-owners',
  sections: {
    General: ['owner', 'type'],
  },
  create: {
    fields: ['active', 'owner', 'type'],
  },
};

export default createFormPage('houseCodeOwners', params);
