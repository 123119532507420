import createListPage from '../listPage';
import entityList from './entityList';

import tabs from '../../../routes/tabs';

const ListPage = createListPage(
  entityList,
  {
    id: { label: 'ID' },
    owner: {
      label: 'Owner',
    },
    type: {
      label: 'Type',
    },
  },
  {
    navTabs: tabs.thirdPartyHouseCodes,
  },
);

export default ListPage;
