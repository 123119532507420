/**
 *                          _
 *         _        ,-.    / )
 *        ( `.     // /-._/ /
 *         `\ \   /(_/ / / /
 *           ; `-`  (_/ / /
 *           |       (_/ /
 *           \
 *            )       /`
 *           /      /`
 * Author: Marwan
 * Date: 07/11/2018
 */

import { combineReducers } from 'redux';

import campaigns from './campaigns';
import members from './members';
import offers from './offers';
import venues from './venues';
import leagues from './leagues';
import games from './games';
import matches from './game1Matches';
import competitions from './competitions';
import competitionRounds from './competitionRounds';
import VenuesDataScope from './VenuesDataScope';
import gamePrizeListPage from './gamePrizes';
import gamePrizeMemberListPage from './gamePrizeMembers';
import gameShareCopy from './gameShareCopy';
import teams from './teams';
import venueGroupsPage from './venueGroups';
import gameMonthPrize from './gameMonthPrize';
import news from './news';
import parameters from './parameters';
import permissions from './permissions';
import tiers from './tiers';
import promocode from './promocode';
import events from './events';
import groupCodeListPage from './groupCode';
import quizMembersPage from './quizMembers';
import quizRoundsPage from './quizRounds';
import quizQuestionsPage from './quizQuestions';
import quizAnswersPage from './quizAnswers';
import quizzes from './quiz';
import sports from './sports';
import trivia from './trivia';
import homepageSections from './homepageSections';
import socialMediaCompetitionCountries from './socialMediaCompetitionCountries';
import channelGroups from './channelGroups';
import channels from './channels';
import mandates from './mandates';
import pills from './pills';
import quizTheme from './quizThemes';
import reports from './reports';
import venueUsers from './venueUsers';
import venueCompleter from './venueCompleter';
import venueComplterVenues from './venueCompleterVenues';
import offerRedemptionCodes from './OfferRedemptionCode';
import importStatus from './importStatus';
import calendarContent from './calendarContent';
import adverts from './adverts';
import assetGroup from './assetGroup';
import consumerEvents from './consumerEvents';
import houseCodeOwners from './thirdPartyHouseCodes';
import houseCodeOwnerLink from './thirdPartyHouseCodeLink/Link';
import redemptionsVenueList from './redemptionsVenues';

const pagesList = [
  redemptionsVenueList,
  houseCodeOwnerLink,
  houseCodeOwners,
  adverts,
  campaigns,
  members,
  offers,
  offerRedemptionCodes,
  venues,
  venueGroupsPage,
  venueUsers,
  games,
  competitions,
  competitionRounds,
  promocode,
  teams,
  news,
  parameters,
  permissions,
  tiers,
  ...gamePrizeListPage,
  ...gamePrizeMemberListPage,
  ...gameMonthPrize,
  ...matches,
  ...leagues,
  ...gameShareCopy,
  events,
  consumerEvents,
  groupCodeListPage,
  quizzes,
  ...quizMembersPage,
  ...quizRoundsPage,
  ...quizQuestionsPage,
  ...quizAnswersPage,
  quizTheme,
  sports,
  trivia,
  homepageSections,
  socialMediaCompetitionCountries,
  channelGroups,
  channels,
  mandates,
  pills,
  reports,
  importStatus,
  venueCompleter,
  venueComplterVenues,
  calendarContent,
  VenuesDataScope,
  assetGroup,
];

const reducers = {};
const pageRoutes = [];

pagesList.forEach(pageList => {
  reducers[pageList.entityName] = pageList.reducer;
  pageRoutes.push(pageList.route);
});

export const reducer = combineReducers({
  ...reducers,
});

export const routes = pageRoutes;
