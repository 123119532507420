import createFormPage from '../formPage';

const params = {
  endpoint: 'venues/house-codes',
  sections: {
    General: ['houseCodeOwnerId', 'houseCode', 'venueId'],
  },
  create: {
    fields: ['active', 'houseCodeOwnerId', 'houseCode', 'venueId'],
  },
};

export default createFormPage('venueHouseCodes', params);
