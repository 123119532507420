import React from 'react';
import MpLink from '../../../components/MpLink/MpLink';

const Users = () => (
  <p>
    Use the{' '}
    <MpLink className="underline" to="/venues/venue-users">
      venue users entity
    </MpLink>
  </p>
);

export default Users;
