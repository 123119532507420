import createListPage from '../listPage';
import eventsList from './LinkentityList';

import tabs from '../../../routes/tabs';

const groupCodeListPage = createListPage(
  eventsList,
  {
    venueId: {
      label: 'Venue ID',
    },
    houseCode: {
      label: 'House code',
    },
    houseCodeOwnerId: {
      label: 'House code owner ID',
    },
  },
  {
    navTabs: tabs.thirdPartyHouseCodeLink,
  },
);

export default groupCodeListPage;
